<div class="timeline animate__animated animate__zoomIn">
    <ng-template ngFor let-experience [ngForOf]="workExperience" let-i="index">
        <h2 class="timeline__item timeline__item--year">
            {{experience.START_DATE}} - {{experience.END_DATE}}
        </h2>

        <div class="timeline__item">
            <div class="timeline__header">
                <h3 class="timeline__title" title="Software Engineer.">{{experience.POSITION}}</h3>
                <hr class="m-0"/>
                <span class="timeline__blurb">{{experience.COMPANY}}</span>
            </div>
            <ul class="mt-2">
                <li *ngFor="let description of experience.JOB_DESCRIPTION">
                    {{description}}
                </li>                
            </ul>
        </div>
    </ng-template>
</div>