<div class="sidebar container-fluid p-0" fxLayout="column" fxLayoutGap="20px">
    <ul fxFlex style="overflow: hidden;overflow-y: auto;">
        <li class="animate__animated animate__zoomIn animate__faster">
            <fa-icon [icon]="faHome" 
                      matTooltip="Home" 
                      routerLink="/"
                      matTooltipPosition="right"></fa-icon>            
        </li>        
        <li class="animate__animated animate__zoomIn animate__faster p-2">
            <img src="assets/profile-image/profile-image_40x40.jpg" 
                 routerLink="about-me" 
                 class="profile-icon" 
                 matTooltip="About Me" 
                 matTooltipPosition="right">
        </li>
        <li class="animate__animated animate__zoomIn animate__faster">
            <fa-icon [icon]="faLaptopHouse" 
                      matTooltip="Work" 
                      routerLink="work"
                      matTooltipPosition="right"></fa-icon>            
        </li>
        <li class="animate__animated animate__zoomIn animate__faster">
            <fa-icon [icon]="faLaptopCode"  routerLink="projects" matTooltip="Projects" matTooltipPosition="right"></fa-icon>            
        </li>
        <li class="animate__animated animate__zoomIn animate__faster">
            <fa-icon [icon]="faCogs" routerLink="skills" matTooltip="Skills" matTooltipPosition="right"></fa-icon>                  
        </li>
        <li class="animate__animated animate__zoomIn animate__faster">
            <fa-icon [icon]="faAddressBook" routerLink="contact" matTooltip="Contact" matTooltipPosition="right"></fa-icon>                  
        </li>        
        <li class="animate__animated animate__zoomIn animate__faster">
            <fa-icon [icon]="faImages" routerLink="gallery" matTooltip="Gallery" matTooltipPosition="right"></fa-icon>                  
        </li>
        <li class="animate__animated animate__zoomIn animate__faster">
            <fa-icon [icon]="faFilePdf" matTooltip="Resume" matTooltipPosition="right" link="resume"></fa-icon>                  
        </li>
        
    </ul>
    <ul fxFlex="155px" >
        <li class="animate__animated animate__zoomIn animate__faster">
            <fa-icon [icon]="faProjectDiagram" 
                      matTooltip="Project docs" 
                      matTooltipPosition="right" link="project-docs"></fa-icon>
        </li>
        <li class="pl-0 pr-0">
            <div>
                <mat-slide-toggle [checked]="switchState" (change)="switchTheme($event)"></mat-slide-toggle>
            </div>
            <div>
                <span>{{switchState ? 'Light Mode' : 'Dark Mode'}}</span>
            </div>
        </li>
    </ul>
</div>