<div mat-dialog-title class="m-0">
    <header fxFlex class="container-fluid shadow-1 position-relative p-2" >
        <div fxFlex="1.2rem">
            <button mat-icon-button class="vertical-center" mat-dialog-close>
                <fa-icon [icon]="faTimes" style="font-size: 1.2rem;" ></fa-icon>
            </button>
        </div>
        <div fxFlex class="text-center position-relative">
            <div class="vertical-center w-100">
                <span class="mat-h1 display-block m-0" style="white-space: nowrap;">{{clickedProject.TITLE}}</span>
                <span class="mat-small display-block m-0" style="white-space: nowrap;">{{clickedProject.SUB_TITLE}}</span>
            </div>
            
        </div>
    </header>
</div>
<div mat-dialog-content class="m-0 p-0 container-fluid" style="width: 100%;" fxLayout="column">
  

  <div fxFlex class="padding2030">
        <div class="alert alert-warning" *ngIf="clickedProject.ARCHIEVED">
            <fa-icon [icon]="faArchive"></fa-icon>
            <span class="ms-1">This project has been archived and there is no activity ongoing.</span>
        </div>
      <div style="background-color: var(--background-color);border-radius: 0.25rem;" class="padding1015">
          <header>
              <span class="mat-h1">Description</span>
              <hr/>
          </header>
          <span class="mat-body">{{clickedProject.DESCRIPTION}}</span>
      </div>
      <div style="background-color: var(--background-color);border-radius: 0.25rem;" class="padding1015 mt-3" *ngIf="clickedProject.TECH_USED.length > 0">
        <header>
            <span class="mat-h1">Tech used</span>
            <hr/>
        </header>        
        <div class="container-fluid p-0 text-center tech-used" fxLayout="row wrap" fxLayoutAlign="center">
            <div fxFlex="50px" class="p-1 skill" *ngFor="let tech of clickedProject.TECH_USED">
                <img src="{{tech.IMAGE}}" matTooltip="{{tech.TITLE}}" matTooltipPosition="below" class="pointer">
            </div>
        </div>
      </div>
      <div style="background-color: var(--background-color);border-radius: 0.25rem;" class="padding1015 mt-3" *ngIf="clickedProject.ARCHITECTURE_DIAGRAM">
        <header>
            <span class="mat-h1">Architecture diagram</span>
            <hr/>
        </header>        
        <div class="text-center">
            <img [src]="clickedProject.ARCHITECTURE_DIAGRAM" style="width: 100%;">
        </div>
      </div>

      <div style="background-color: var(--background-color);border-radius: 0.25rem;" class="padding1015 mt-3" *ngIf="clickedProject.GALLERY.length > 0">
        <header>
            <span class="mat-h1">Gallery</span>
            <hr/>
        </header>        
        <div>            
            <div class="container-fluid" fxLayoutAlign="center">
                <div fxFlex="500px" fxFlex.sm="100%" fxFlex.xs="100%" style="border-radius: 0.25rem;">
                    <ngb-carousel *ngIf="images">
                        <ng-template ngbSlide *ngFor="let image of clickedProject.GALLERY">
                            <div class="picsum-img-wrapper">
                                <img [src]="image.IMAGE" style="width: 100%;border-radius: 0.25rem;" alt="Random first slide">
                            </div>
                        </ng-template>
                    </ngb-carousel>
                </div>
            </div>            
        </div>
      </div>
  </div>
</div>