<div class="container-fluid h-100 p-0 max-supported-width" fxLayout="column">
    <header fxFlex="80px" class="padding2030">
        <span class="mat-display-1">About me</span>
        <hr>
    </header>
    <div fxFlex class="padding2030 about-me">
        <div style="max-width: 500px; margin: auto;">
            <img src="assets/illustrations/hello-illustration.svg">
        </div>
        <p>
            Hi, My name is Bibek Saini and I am Full Stack Software Engineer and currently I am working as Senior Software Engineer at Scandinavian company called CatalystOne.             
        </p>
        <p>
            I am a passionate software engineer who love to learn, experiment and build something that creates value for people.
        </p>
        <p>
            Other than that I love playing and collecting PC games.
        </p>
        <p>
            Nowadays, I am experimenting on micro-frontend and in process of learning React.js so I can build smaller independent components/modules that are easy to manage and can be re-used.
        </p>
        <p>
            Besides, I am working on micro-service architecture SaaS application which is built on various technologies like Java Spring Framework, ASP.net Core as backend micro-service and Angular as front-end client app. Micro apps are shipped as docker containers images and leverages Kubernetes for automating deployment, scaling, and management of micro apps.            
        </p>

    </div>
</div>