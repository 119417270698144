<div class="container-fluid p-0 max-supported-width" fxLayout="column">
    <header fxFlex="80px" class="padding2030">
        <span class="mat-display-1">Skills</span>
        <hr>
    </header>

    <div fxFlex class="container-fluid p-0" fxLayout="column">
        <header class="padding2030 text-center">
            <span class="mat-display-1 display-block m-0">Full stack</span>
            <span class="mat-small display-block m-0">Some of technologies I used for my work.</span>               
        </header>
        <div fxFlex class="container-fluid p-0" fxLayoutAlign="center" fxLayout="row wrap">                       
            <div fxFlex="280px" fxFlex.xs="100%" class="p-2" *ngFor="let skillCategory of skillCatergories">
                <mat-card>
                    <mat-card-title class="p-1">{{skillCategory.TITLE}}</mat-card-title>
                    <mat-card-content class="container-fluid p-0 text-center" fxLayout="row wrap" fxLayoutAlign="center">
                        <div fxFlex="50px" class="p-1 skill" *ngFor="let skill of skillCategory.SKILLS">
                            <img src="{{skill.IMAGE}}" matTooltip="{{skill.TITLE}}" matTooltipPosition="below" class="pointer">
                        </div>                                                
                    </mat-card-content>
                </mat-card>
            </div>            
        </div>
    </div>
</div>