<div class="container-fluid h-100 p-0 max-supported-width" fxLayout="column">
    <header fxFlex="80px" class="padding2030">
        <span class="mat-display-1">Projects</span>
        <hr>
    </header>
    <div fxFlex>
        <div class="container-fluid padding1015 mt-3" fxLayoutAlign="center" fxLayout="row wrap">
            <div fxFlex="320px" class="p-3" style="position: relative;" *ngFor="let project of projects">
                <!-- Project card -->
                <project-card [projectData]="project"></project-card>
            </div>
        </div>
    </div>
</div>